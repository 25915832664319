import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/styles.scss'
import {Parallax, ParallaxBanner} from "react-scroll-parallax";

const NosotrosPage = () => {
    return (
        <Layout>

            <div className={'wrappertiposlider'}>
                <h1
                    data-sal="slide-right"
                    data-sal-duration={"600"}>+40 años en la industria nos respaldan.</h1>
            </div>


            <ParallaxBanner
                layers={[{
                    speed: 30,
                    children: (
                        <StaticImage style={{width: '100%'}} src="../images/bg-nosotros.png" alt="Nosotros ALEMAR"/>
                    )
                }]}
                className="bg-parallax"
            />

            <div className={'wrapperbase nosotros-offers'}>
                <h2 className={'h2nosotros1'}
                    data-sal="slide-up"
                    data-sal-duration={"600"}>Ofrecemos servicios integrados.</h2>
                <div className={'bloqueright'}
                     data-sal="slide-left"
                     data-sal-duration={"600"}>
                    <p className={'especial2 pnosotros1'}>Compartimos nuestra experiencia para beneficio de las
                        empresas.</p>
                    <hr></hr>
                    <StaticImage className={'img'} src="../images/Group-149.png" alt="Servicios ALEMAR"/>
                </div>
            </div>

            <div className={'nosotros-vision image-info'}>
                <Parallax speed={-10} className={'left'}>
                    <StaticImage className={'img'} src="../images/verticalimgnosotros1.png" alt="Servicios ALEMAR"/>
                </Parallax>
                <Parallax speed={10} className={'right'}>
                    <h4 className={'h4nosotros1'}>Nuestra <span>visión</span></h4>
                    <p className={'especial2 pnosotros2'}>Ser reconocidos como
                        un <b>socio de valor y estratégico</b> para las soluciones de automatización de procesos y
                        los servicios administrados de impresión. </p>
                </Parallax>
            </div>
            <div className={'nosotros-numbers'}>
                <ul>
                    <li>
                        <h4>+6 millones</h4>
                        <p className={'contenido1'}>de hojas ahorradas</p>
                    </li>
                    <li>
                        <h4>+50</h4>
                        <p className={'contenido1'}>empresas y contando.</p>
                    </li>
                    <li>
                        <h4>+10,000</h4>
                        <p className={'contenido1'}>documentos digitalizados</p>
                    </li>
                </ul>
            </div>
            <div className={'wrapperbaseblock nosotros-team'}>
                <h3 className={'equipo'}>
                    Somos T.I.O. Alemar
                </h3>
                <ul className={'listaequipo'}>
                    <li
                    data-sal="zoom-out"
                    data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/team/t1.png" alt="Servicios ALEMAR"/>
                    </li>
                    <li
                    data-sal="zoom-out"
                    data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/team/t2.png" alt="Servicios ALEMAR"/>
                    </li>
                    <li
                        data-sal="zoom-out"
                        data-sal-duration={"600"}>
                        <StaticImage className={'img'} src="../images/team/t3.png" alt="Servicios ALEMAR"/>
                    </li>
                </ul>
                <hr></hr>
            </div>

            <div className={'nosotros-clients'}>
                <p className={'especial2'}>Algunos <b>clientes</b> que han confiado en nosotros.</p>
                <ul className={'logosclientes'}>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={50} className="img" src="../images/logosclientes/01-Adidas-Logo.png" alt="Lexmark logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={50} className="img" src="../images/logosclientes/02-Logo-Cuetara.png" alt="AWS Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={50} className="img" src="../images/logosclientes/03-logo-red.png" alt="Kodak Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={50} className="img" src="../images/logosclientes/04-Logo-nestle.png" alt="M Files Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={60} className="img" src="../images/logosclientes/05-logo-bennetts.png" alt="DOQSOFT Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={50} className="img" src="../images/logosclientes/06-logoa-Alcon.png" alt="Lexmark logo"/></li>
                    <li className={'main'}><StaticImage className="img" src="../images/logosclientes/13-logo-miro.jpg" alt="Miro Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={50} className="img" src="../images/logosclientes/07-logo-finamex.png" alt="AWS Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={60} className="img" src="../images/logosclientes/08-logo-chupachups.png" alt="Kodak Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={55} className="img" src="../images/logosclientes/09-logo-lacoste.png" alt="M Files Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={50} className="img" src="../images/logosclientes/10-logo-praxair.png" alt="DOQSOFT Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={55} className="img" src="../images/logosclientes/11-logo-diga.png" alt="Lexmark logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={60} className="img" src="../images/logosclientes/12-logo-ssl.png" alt="AWS Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={60} className="img" src="../images/logosclientes/14-logo-olivares.png" alt="M Files Logo"/></li>
                    <li><StaticImage transformOptions={{fit: 'contain'}} height={55} className="img" src="../images/logosclientes/15-logo-maersk.png" alt="DOQSOFT Logo"/></li>
                </ul>
            </div>

        </Layout>
    )
}

export default NosotrosPage
export const Head = () => {
    return (
        <>
            <title>Somos tu socio digital con más de 40 años de experiencia</title>
            <meta name="description" content="Ofrecemos servicios integrados para automatizar y digitalizar tu negocio."/>
        </>
    )
}

